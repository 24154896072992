import { dAppName } from 'config';
import NftZoneAirdrop from 'pages/AirdropZone';
import DrinkTopup from 'pages/DrinkTopup';
import ExchangeForDrinks from 'pages/ExDrinks';
import FestivalEntrance from 'pages/FestEnter';
import withPageTitle from './components/PageTitle';
export const routeNames = {
  home: '/',
  unlock: '/unlock',
  exchangeForDrinks: '/exchange-for-drinks',
  festivalEntrance: '/festival-entrance',
  nftZoneAirdrop: '/nft-zone-airdrop',
  drinkTopup: '/drink-topup'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    component: FestivalEntrance
  },
  {
    path: routeNames.unlock,
    component: ExchangeForDrinks
  },
  {
    path: routeNames.festivalEntrance,
    component: FestivalEntrance
  },
  {
    path: routeNames.exchangeForDrinks,
    component: ExchangeForDrinks
  },
  {
    path: routeNames.nftZoneAirdrop,
    component: NftZoneAirdrop
  },
  {
    path: routeNames.drinkTopup,
    component: DrinkTopup
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
