export const dAppName = 'SAGA';

export const gateway = 'https://gateway.multiversx.com';

export const api = 'https://api.multiversx.com';

export const mainTokenIdentifier = 'SAGA23-ad756e';
export const drinks1TokenIdentifier = 'SAGA23V50-9e6f41';
export const drinks2TokenIdentifier = 'SAGA23V80-787021';
export const airdropTokenIdentifier = 'SAGA23-0929c9';

export const environment = 'mainnet';

export const contractAddresses = [
  'erd1qqqqqqqqqqqqqpgq2aje9lmhwyndg9us2xwv069jc7dhrn39n62qddum72',
  'erd1qqqqqqqqqqqqqpgqpyj95yrtg46efsz2nr6f6kszq93c2cd748ns8gepjp',
  'erd1qqqqqqqqqqqqqpgqx6dg69plnjche0csus4vc2qkjg9vdzvm433qc0grc6'
];

export const nftTiers = {
  Basic: ['1x Wristband'],
  'Special Merch': ['1x Wristband', '1x Merch'],
  'Drinks Voucher': ['1x Wristband', '1x €50 Drinks Voucher NFT'],
  'SAGA23 + SAGA24': ['1x Wristband'],
  'Plus One (Guest)': ['2x Wristband'],
  'Lifetime Access': ['1x Wristband'],
  'Lifetime VIP Access': ['1x VIP Wristband']
};
