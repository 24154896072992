import * as React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transaction, TransactionWatcher } from '@multiversx/sdk-core';
import { Address, TransactionPayload } from '@multiversx/sdk-core/out';
import {
  useGetAccount,
  useGetAccountInfo,
  useGetAccountProvider
} from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { getLatestNonce, refreshAccount } from '@multiversx/sdk-dapp/utils';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { gateway } from 'config';
import { TransactionsToastList } from '@multiversx/sdk-dapp/UI';

const PageNotFound = () => {
  const { address, shard } = useGetAccountInfo();
  const account = useGetAccount();
  const { pathname } = useLocation();
  const { provider } = useGetAccountProvider();

  const sendDrinksTransction = async () => {
    const sagaTransaction = new Transaction({
      value: 0,
      data: new TransactionPayload('test'),
      receiver: new Address(address),
      sender: new Address(address),
      gasLimit: 0,
      nonce: account.nonce,
      chainID: '1'
    });
    await refreshAccount();
    const tx = await provider.signTransaction(sagaTransaction);
    const plainTx = tx.toPlainObject();
    const resp = await axios.post(
      `http://localhost:3000/transactions?shard=${shard}`,
      plainTx
    );
    const relayedTx = Transaction.fromPlainObject(resp.data);
    console.log(relayedTx);
    const networkProvider = new ProxyNetworkProvider(gateway);
    await networkProvider.sendTransaction(relayedTx);

    // await sendTransactions({
    //   transactions: sagaTransaction,
    //   transactionsDisplayInfo: {
    //     processingMessage: 'Loading SAGA exp...',
    //     errorMessage: 'Error occured',
    //     successMessage: 'Success'
    //   },
    //   redirectAfterSign: false
    // });
  };

  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='row w-100'>
        <div className='col-12 col-md-8 col-lg-5 mx-auto'>
          <div className='card shadow-sm rounded p-4 border-0'>
            <div className='card-body text-center d-flex flex-column justify-content-center'>
              <div className='dapp-icon icon-medium'>
                <FontAwesomeIcon
                  icon={faSearch}
                  className='mx-auto text-muted fa-3x mb-2'
                />
              </div>
              <span className='h4 empty-heading mt-3'>Page not found</span>
              <button onClick={sendDrinksTransction}>test</button>
              <span className='empty-details'>{pathname}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
