import React, { useEffect, useState } from 'react';
import {
  Address,
  Transaction,
  TransactionPayload
} from '@multiversx/sdk-core/out';
import {
  useGetAccountInfo,
  useGetPendingTransactions
} from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { WalletConnectLoginButton } from '@multiversx/sdk-dapp/UI';
import { logout, refreshAccount } from '@multiversx/sdk-dapp/utils';
import axios from 'axios';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import {
  api,
  mainTokenIdentifier,
  drinks2TokenIdentifier,
  nftTiers,
  contractAddresses,
  drinks1TokenIdentifier
} from 'config';
import { routeNames } from 'routes';

export const buildNftPayLoadFromString = (id: string) => {
  const res = id.split('-');
  const hexid = Buffer.from(res[0] + '-' + res[1]).toString('hex');
  const hexnonce = res[2];
  return '@' + hexid + '@' + hexnonce + '@01';
};

const DrinkTopup = () => {
  const { address, shard } = useGetAccountInfo();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = Boolean(address);
  const [walletNfts, setWalletNfts] = useState<any[]>([]);
  const [selectedWalletNfts, setSelectedWalletNfts] = useState<any[]>([]);
  const [sendStep, setSendStep] = useState<number>(0);
  const [contractAddress, setContractAddress] = useState<string>(
    contractAddresses[0]
  );

  const showReceivables = (nft: any) => {
    const hasKey = Object.keys(nftTiers).includes(
      nft['metadata']['attributes'][0]['value']
    );
    if (hasKey) {
      return (
        <div>
          {nftTiers[nft['metadata']['attributes'][0]['value']].map(
            (tier: any, index: any) => (
              <div key={index}>{tier}</div>
            )
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div>{nft['metadata']['attributes'][0]['value']}</div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (shard) {
        setContractAddress(contractAddresses[shard]);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    console.log(isLoggedIn);
    if (isLoggedIn) {
      axios
        .get(
          `${api}/accounts/${address}/nfts?size=100&collections=${drinks1TokenIdentifier}%2C${drinks2TokenIdentifier}`
        )
        .then((res) => {
          setWalletNfts(res.data);
        });
    }
  }, [isLoggedIn, hasPendingTransactions]);

  const sendDrinksTransction = async () => {
    if (selectedWalletNfts.length > 0) {
      let hexamount = selectedWalletNfts.length.toString(16);
      if (hexamount.length % 2 !== 0) {
        hexamount = '0' + hexamount;
      }
      let data =
        'MultiESDTNFTTransfer' +
        '@' +
        new Address(contractAddress).hex() +
        '@' +
        hexamount;
      selectedWalletNfts.forEach((nft) => {
        data += buildNftPayLoadFromString(nft);
      });
      data += '@' + Buffer.from('welcomeToSaga').toString('hex');
      data += '@02';
      const sagaTransaction = new Transaction({
        value: 0,
        data: new TransactionPayload(data),
        receiver: new Address(address),
        sender: new Address(address),
        gasLimit: Math.min(
          5000000 * (selectedWalletNfts.length + 1),
          600000000
        ),
        chainID: '1'
      });
      await refreshAccount();
      await sendTransactions({
        transactions: sagaTransaction,
        transactionsDisplayInfo: {
          processingMessage: 'Loading SAGA exp...',
          errorMessage: 'Error occured',
          successMessage: 'Success'
        },
        redirectAfterSign: false
      });
      setSendStep(0);
    }
  };

  return (
    <div className='gallery-container mt-5'>
      {!isLoggedIn && (
        <div className='d-flex flex-column justify-content-center align-items-centers'>
          <h1>Connect using the xPortal App</h1>
          <h2 className='mb-5 text-center' style={{ color: '#808080' }}>
            Press the button and scan the QR
          </h2>
          <div className='d-flex flex-row justify-content-center'>
            <WalletConnectLoginButton
              logoutRoute={window.location.href}
              loginButtonText={'Connect'}
              buttonClassName={'unlockButton'}
              isWalletConnectV2={true}
            />
          </div>
        </div>
      )}

      {isLoggedIn && sendStep === 0 && (
        <div className='d-flex flex-column'>
          <div className='d-flex flex-column justify-content-center align-items-center mb-5'>
            <h2 className='text-white'>Drinks Topup</h2>
            <h1
              className='px-5 py-4'
              style={{ border: '2px solid #ffffff', borderRadius: '12px' }}
            >
              <b>Your address:</b>{' '}
              {address.slice(0, 10) + ' ... ' + address.slice(-10)}
            </h1>
          </div>
          {walletNfts.length > 0 ? (
            <>
              <div className='gallery'>
                {walletNfts.map((nft, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginRight: '1rem',
                        marginLeft: '1rem',
                        marginBottom: '1rem',
                        marginTop: '1rem',
                        opacity: selectedWalletNfts.includes(nft.identifier)
                          ? '1'
                          : '0.8'
                      }}
                      onClick={() => {
                        if (selectedWalletNfts.includes(nft.identifier)) {
                          setSelectedWalletNfts(
                            selectedWalletNfts.filter(
                              (nftId) => nftId !== nft.identifier
                            )
                          );
                        } else {
                          setSelectedWalletNfts([
                            ...selectedWalletNfts,
                            nft.identifier
                          ]);
                        }
                      }}
                    >
                      <div
                        className={
                          'nft-card d-flex flex-column justify-content-center align-items-center'
                        }
                        style={{ width: '20rem', margin: 'auto' }}
                      >
                        <img
                          src={nft['media'][0]['thumbnailUrl']}
                          className={'card-img-top'}
                        />
                        <div
                          className='d-flex flex-column justify-content-center text-center mt-2'
                          style={{ color: '#808080' }}
                        >
                          <div>{nft['identifier']}</div>
                          <div className='text-white mt-3 mb-3'>
                            {showReceivables(nft)}
                          </div>
                          <div
                            className='d-flex flex-column justify-content-center align-items-center'
                            style={{
                              width: '40px',
                              height: '40px',
                              border: '2px solid #ffffff',
                              borderRadius: '2px',
                              margin: 'auto',
                              textAlign: 'center'
                            }}
                          >
                            <div
                              style={{
                                display: selectedWalletNfts.includes(
                                  nft.identifier
                                )
                                  ? 'block'
                                  : 'none'
                              }}
                            >
                              <IoCheckmarkSharp
                                color='#ffffff'
                                fontSize={'30px'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='text-white text-center d-flex flex-row justify-content-center align-items-center m-3'>
                I acknowledge that by exchanging a Saga NFT Drink, I will no
                longer have it in my wallet.
              </div>
            </>
          ) : (
            <div className='d-flex flex-column justify-content-center align-items-center text-center'>
              <h1 className='text-white my-5 mx-5' style={{ fontSize: '40px' }}>
                <b>You do not own any SAGA NFTs!</b>
              </h1>
              <h2
                className='mx-5'
                style={{ color: '#808080', fontSize: '24px' }}
              >
                Please make sure you are using the correct wallet. Airdrops{' '}
                <b>DO NOT</b> count as entry tickets
              </h2>
            </div>
          )}
        </div>
      )}
      {isLoggedIn && sendStep === 1 && (
        <div className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-center align-items-center mb-5'>
            <h1
              className='px-5 py-4'
              style={{ border: '2px solid #ffffff', borderRadius: '12px' }}
            >
              <b>Your address:</b>{' '}
              {address.slice(0, 10) + ' ... ' + address.slice(-10)}
            </h1>
          </div>
          {walletNfts.length > 0 ? (
            <div className='gallery'>
              {walletNfts
                .filter((nft) => selectedWalletNfts.includes(nft.identifier))
                .map((nft, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginRight: '1rem',
                        marginLeft: '1rem',
                        marginBottom: '1rem',
                        marginTop: '1rem'
                      }}
                    >
                      <div
                        className={
                          'nft-card d-flex flex-column justify-content-center align-items-center'
                        }
                        style={{ width: '20rem', margin: 'auto' }}
                      >
                        <img
                          src={nft['media'][0]['thumbnailUrl']}
                          className={'card-img-top'}
                        />
                        <div
                          className='d-flex flex-column justify-content-center text-center mt-2'
                          style={{ color: '#808080' }}
                        >
                          <div>{nft['identifier']}</div>
                          <div className='text-white mt-3 mb-3'>
                            {showReceivables(nft)}
                          </div>
                          <div
                            className='d-flex flex-column justify-content-center align-items-center'
                            style={{
                              width: '40px',
                              height: '40px',
                              border: '2px solid #ffffff',
                              borderRadius: '2px',
                              margin: 'auto',
                              textAlign: 'center'
                            }}
                          >
                            <div
                              style={{
                                display: selectedWalletNfts.includes(
                                  nft.identifier
                                )
                                  ? 'block'
                                  : 'none'
                              }}
                            >
                              <IoCheckmarkSharp
                                color='#ffffff'
                                fontSize={'30px'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className='d-flex flex-column justify-content-center align-items-center text-center'>
              <h1 className='text-white my-5 mx-5' style={{ fontSize: '40px' }}>
                <b>You do not own any SAGA NFTs!</b>
              </h1>
              <h2
                className='mx-5'
                style={{ color: '#808080', fontSize: '24px' }}
              >
                Please make sure you are using the correct wallet. Airdrops{' '}
                <b>DO NOT</b> count as entry tickets
              </h2>
            </div>
          )}
        </div>
      )}
      <div className='d-flex flex-row justify-content-center align-items-center'>
        {isLoggedIn && (
          <Link
            to={routeNames.home}
            className='btn connect my-5 mx-2'
            data-testid='loginBtn'
            onClick={() => {
              if (sendStep === 0) {
                logout(`${window.location.href}`);
              } else {
                setSendStep(0);
              }
            }}
          >
            <span>GO BACK</span>
          </Link>
        )}
        {isLoggedIn && selectedWalletNfts.length > 0 && (
          <button
            className='btn connect my-5 mx-2'
            onClick={() => {
              if (sendStep === 0 && selectedWalletNfts.length > 0) {
                setSendStep(1);
              } else {
                if (selectedWalletNfts.length > 0) {
                  sendDrinksTransction();
                }
              }
            }}
          >
            CONTINUE
          </button>
        )}
      </div>
    </div>
  );
};

export default DrinkTopup;
