import React from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import logo from 'assets/img/egold-02.png';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';
const Navbar = () => {
  return <BsNavbar className=''></BsNavbar>;
};

export default Navbar;
